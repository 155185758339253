import router from './router'
import { getToken } from './utils/auth'

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  // 兼容IE
  window.scrollTo(0, 0);
  let token = getToken()
  if (to.meta.requireAuth) {
    if (token) {
      //  当前的token是否存在
      next()
    } else {
      next({
        path: '/loginStore',
        query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  } else {
    next()
  }
})
