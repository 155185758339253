import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import 'components'
import '../src/styles/tailwindcss.css'
import '@/styles/icon.scss'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import Vconsole from 'vconsole' //开发环境使用，生产环境自动取消
import './permission'
import directive from './directive' //directive
import MessageTip from '@/components/MessageTip'
Vue.use(MessageTip)
if (process.env.NODE_ENV == 'development') {
  new Vconsole()
}

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(directive)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
